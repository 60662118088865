const getApiBaseUrl = () => process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";

const config = {
  apiBaseUrl: getApiBaseUrl(),
  localStorageKeys: {
    token: "access_token_key"
  },
  defaultDateFormat: "YYYY-MM-DD",
  dateFormat: {
    api: "YYYY-MM-DD HH:mm:ss",
    front: "YYYY-MM-DD HH:mm:ss",
    shortFormat: "YYYY-MM-DD"
  },
  generalError: "Internal error",
  appName: "SP Dashboard"
};

export default config;
