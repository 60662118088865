import { Layout } from "antd";

/**
 * Main Container
 * @param  {object} props: { children, className, style }
 * @description Container page component
 */
const MainContainer = ({ children }) => (
  <Layout.Content className="layout-container-outer">
    <div className="layout-content-inner">{children}</div>
  </Layout.Content>
);

export default MainContainer;
