import { Layout } from "antd";
import { NavLink } from "react-router-dom";
import { useLoggedUser } from "../../hooks/useLoggedUser";
import routes from "../../pages/App/routes";
import TabsNavigation from "./TabsNavigation";

/**
 * Header
 * @function Header
 * @description Header component
 */
const Header = () => {
  const { view } = useLoggedUser();

  return (
    <Layout.Header className="layout-header">
      <NavLink to={routes.PLANUNG} className="dashboard-item">
        SP Dashboard
      </NavLink>
      {view && <TabsNavigation {...{ view }} />}
    </Layout.Header>
  );
};

export default Header;
