import { Button as AntButton } from "antd";
import classNames from "classnames";
import { CaretRightOutlined } from "@ant-design/icons";
import "../Button/style.scss";

const Button = (props) => {
  const { iconRight, className, ...rest } = props;
  return <AntButton className={classNames(className, { "icon-right": iconRight })} {...rest} />;
};

const IconButton = (props) => {
  const { iconRight, className, children, ...rest } = props;
  return (
    <AntButton size="large" className={classNames(className, { "icon-right": iconRight })} {...rest}>
      {children} <CaretRightOutlined />
    </AntButton>
  );
};

export { IconButton };
export default Button;
