import forEach from "lodash/forEach";
import { getColumnSelectFilterProps } from "./filterTypes/columnSelect";
import { getColumnSearchFilterProps } from "./filterTypes/columnSearch";
import { getColumnDateFilterProps } from "./filterTypes/columnDate";

/***
 * @description: FILTER_TYPE_DATE_RANGE return filter format:
 * period_start: 2022-06-28
 * period_end: 2022-06-30
 */
export const FILTER_TYPE_DATE_RANGE = "date-range";

/***
 * @description: FILTER_TYPE_SELECT return filter format:
 * filters[scopes][]: 862
 * filters[scopes][]: 845
 */
export const FILTER_TYPE_SELECT = "select";

/***
 * @description: FILTER_TYPE_STRING return filter format:
 * filters[scopes][]: string
 * filters[scopes][]: test
 */
export const FILTER_TYPE_STRING = "string";

export const applyColumnsFilterSort = (columns, tableService) => {
  const formattedColumns = columns.map((item) => ({ ...item }));
  const tableParams = tableService.getAllParameters();
  const sort = (tableParams?.orderBy || [])[0] || {};
  const defaultSort = (tableParams?.defaultOrderBy || [])[0] || {};
  const sorterColumn = sort.column || defaultSort.column;
  const sorterType = sort.type || defaultSort.type;

  forEach(formattedColumns, (col, idx) => {
    if (sorterColumn && sorterType && col.dataIndex === sorterColumn) {
      formattedColumns[idx].sortOrder = sorterType === "asc" ? "ascend" : "descend";
    }

    if (col.filterType === FILTER_TYPE_STRING) {
      formattedColumns[idx] = {
        ...col,
        ...getColumnSearchFilterProps({ tableParams, column: col })
      };
    }

    if (col.filterType === FILTER_TYPE_DATE_RANGE) {
      formattedColumns[idx] = {
        ...col,
        ...getColumnDateFilterProps({ tableParams, column: col })
      };
    }

    if (col.filterType === FILTER_TYPE_SELECT) {
      formattedColumns[idx] = {
        ...col,
        ...getColumnSelectFilterProps({ tableParams, column: col })
      };
    }
  });

  return formattedColumns;
};

export const saveTableParams = (tableService, tableKeyName) => {
  const lastTableParams = JSON.stringify(tableService.getAllParameters());
  localStorage.setItem(tableKeyName, lastTableParams);
};
