import { Button, Input, Space } from "antd";
import React from "react";
import isEmpty from "lodash/isEmpty";
import { SearchOutlined } from "@ant-design/icons";

let searchRef = null;
export const getColumnSearchFilterProps = ({ tableParams = {}, column = {} }) => {
  const filterColumn = tableParams.filters?.filter((item) => item.columns === column.dataIndex)[0];
  const filterValue = filterColumn?.values || null;

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="table-filter-dropdown search-filter-dropdown">
        <Input
          ref={(node) => {
            searchRef = node;
          }}
          value={isEmpty(selectedKeys) ? undefined : selectedKeys}
          onChange={(e) => setSelectedKeys(e.target.value)}
          onPressEnter={() => confirm()}
          allowClear
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} size="small" style={{ width: 80 }}>
            Apply
          </Button>
          <Button
            style={{ width: 80 }}
            size="small"
            type="secondary"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            Preview
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <SearchOutlined />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchRef.select(), 100);
      }
    },
    filteredValue: filterValue
  };
};
