import React from "react";
import { Typography, message } from "antd";
import Form from "../../../components/Form";
import { PasswordInput } from "../../../components/Input";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import "../style.scss";
import { passwordValidation } from "../../../components/Form/utils";
import { IconButton } from "../../../components/Button";
import { fieldRules } from "../../../components/Form/methods";

const ChangePasswordForm = ({ layout, className }) => {
  const [form] = Form.useForm();

  const { loading, generalError, submit } = useFormSubmit({
    form,
    route: "/api/change-password",
    onSuccess: () => {
      message.success("Password was changed successfully");
    }
  });

  return (
    <div className={className}>
      <Typography.Title level={5} className="section-title">
        Change password
      </Typography.Title>
      <Form
        onSubmit={submit}
        form={form}
        errors={generalError}
        loading={loading}
        layout={layout}
        className="pt-10 flex-items-center"
      >
        <Form.Item
          name="current_password"
          rules={fieldRules({
            required: {
              message: "This field is required"
            }
          })}
        >
          <PasswordInput placeholder="current password" />
        </Form.Item>
        <Form.Item name="password" rules={passwordValidation}>
          <PasswordInput placeholder="new password" />
        </Form.Item>
        <Form.Item
          name="password_confirmation"
          dependencies={["password"]}
          rules={[
            ...passwordValidation,
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The password does not match");
              }
            })
          ]}
        >
          <PasswordInput placeholder="confirm password" />
        </Form.Item>
        <div className="form-actions">
          <IconButton type="primary" htmlType="submit" disabled={loading} loading={loading} className="submit-button">
            Submit
          </IconButton>
        </div>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;
