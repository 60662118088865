import { fieldRules } from "./methods";

export const passwordValidation = [
  ...fieldRules({
    required: {
      message: "This field is required"
    },
    max: {
      value: 255,
      message: "Maximum length exceeded"
    },
    min: {
      value: 8,
      message: "Password must be at least 8 characters long"
    }
  }),
  () => ({
    validator(rule, value) {
      if (/[A-Za-z]/.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject("Password must contain uppercase and lowercase letters");
    }
  }),
  () => ({
    validator(rule, value) {
      if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject("Password must contain at least one special character");
    }
  })
];

export const emailValidation = [
  ...fieldRules({
    required: {
      message: "This field is required"
    },
    max: {
      value: 255,
      message: "Maximum length exceeded"
    },
    email: {
      message: "Invalid email address"
    }
  })
];
