const USER_TYPES = {
  ADMIN: "ADMIN",
  DEM: "DEM",
  OPEX: "OPEX",
  IBEX: "IBEX",
  OBU: "OBU",
  CVRM: "CVRM",
  "V&I": "V&I",
  "R&I": "R&I"
};

const BUSINESS_UNITS = {
  IBEX: "IBEX",
  DEM: "DEM",
  OBU: "OBU",
  CVRM: "CVRM",
  "V&I": "V&I",
  "R&I": "R&I"
};

const BUSINESS_UNITS_NO_DEM = {
  IBEX: "IBEX",
  OBU: "OBU",
  CVRM: "CVRM",
  "V&I": "V&I",
  "R&I": "R&I"
};

const ADMIN_TYPES = {
  ADMIN: "ADMIN",
  OPEX: "OPEX"
};

const MODULES = {
  SPRINTPLANUNG: "SPRINTPLANUNG"
};

const DEFAULT_PROJECT_FILTER = "Alle Auswählen";
const DEFAULT_BU_FILTER = "ALL BU";
const DEFAULT_SHAREDSP_PROJECT_FILTER = "Web Pages";
const DEFAULT_QUARTER = "Q1";
const DEFAULT_YEAR = new Date().getFullYear();

const MONTH_LABELS = [
  "Januar",
  "Feburar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "October",
  "November",
  "Dezember"
];

const PROJECT_FILTER_API = "/api/project-filters";
const SPRINTS_API = "/api/sprint-planning/sprints";
const PLANNING_IST_STORYPOINTS_CHART = "api/planning-ist/story-points-chart";
const PLANNING_IST_EPICS_TABLE = "api/planning-ist/epics-table";
const PLANNING_IST_STORY_POINTS_TABLE = "api/planning-ist/story-points-table";

const QUARTERS = ["Q1", "Q2", "Q3", "Q4"];
const PLAN_TEMPO = "PLAN/TEMPO";
const PLAN_BURNED = "PLAN/BURNED";

export {
  USER_TYPES,
  BUSINESS_UNITS,
  BUSINESS_UNITS_NO_DEM,
  ADMIN_TYPES,
  MODULES,
  DEFAULT_BU_FILTER,
  DEFAULT_PROJECT_FILTER,
  DEFAULT_SHAREDSP_PROJECT_FILTER,
  DEFAULT_QUARTER,
  DEFAULT_YEAR,
  MONTH_LABELS,
  PROJECT_FILTER_API,
  SPRINTS_API,
  PLANNING_IST_STORYPOINTS_CHART,
  PLANNING_IST_EPICS_TABLE,
  PLANNING_IST_STORY_POINTS_TABLE,
  PLAN_BURNED,
  PLAN_TEMPO,
  QUARTERS
};

export const OVERALL_RESULT = "Gesamtergebnis";
export const STATUS = {
  TODO: "ToDo",
  INARBEIT: "In Arbeit",
  WAITING: "Waiting",
  FERTIG: "Fertig",
  "TESTING/REVIEW": "Testing/Review"
};
