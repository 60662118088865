import React, { useState } from "react";
import { Layout as AntdLayout } from "antd";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import MainContainer from "./MainContainer";

const COLLAPSED_SIDEBAR_WIDTH = 1170;

/**
 * Layout
 * @function Layout
 * @description Layout component
 */
const Layout = () => {
  // const windowSize = useHtmlSize();
  // const previousWindowSize = usePrevious(windowSize);
  const [collapsed, setCollapsed] = useState(window.innerWidth < COLLAPSED_SIDEBAR_WIDTH);

  const handleCollapse = (collapse) => {
    setCollapsed(collapse);
  };
  //
  // useEffect(() => {
  //   if (!collapsed && windowSize?.width <= COLLAPSED_SIDEBAR_WIDTH && windowSize?.width !== previousWindowSize?.width) {
  //     setCollapsed(true);
  //   }
  //
  //   if (collapsed && windowSize?.width > COLLAPSED_SIDEBAR_WIDTH && windowSize?.width !== previousWindowSize?.width) {
  //     setCollapsed(false);
  //   }
  // }, [windowSize, collapsed, previousWindowSize]);

  // useEffect(() => {
  //   const appContainerElement = document.getElementsByClassName("app-global-container")[0];
  //
  //   if (appContainerElement) {
  //     appContainerElement.classList[collapsed ? "add" : "remove"]("navigation-collapsed");
  //   }
  // }, [collapsed]);

  return (
    <AntdLayout style={{ minHeight: "100vh" }}>
      {/*<Sidebar collapsed={collapsed} handleCollapse={handleCollapse} />*/}
      <AntdLayout className="site-layout">
        <Header handleCollapse={handleCollapse} collapsed={collapsed} />
        <MainContainer>
          <Outlet />
        </MainContainer>
        {/*<Footer />*/}
      </AntdLayout>
    </AntdLayout>
  );
};

export default Layout;
