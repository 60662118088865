import React from "react";
import { Input } from "../../Input";
// import ExportDropdown from "../ExportDropdown";
// import NewItemButton from "./NewItemButton";
// import MassDeleteButton from "./MassDeleteButton";

const TableFiltersActions = (props) => {
  const {
    withSearch,
    search,
    // columns,
    // tableName,
    // tableService,
    handleSearchChange,
    // externalFilters,
    // selectedRows = [],
    // exportProps = {},
    // newItemProps = {},
    // massDeleteProps = {},
    externalActions
  } = props;
  // const exportAccess = exportProps.access;
  // const newItemAccess = newItemProps.access;
  // const massDeleteAccess = massDeleteProps.access;

  return (
    <div className="table-outer-filters flex flex-items-start">
      <div className="table-left-actions">
        {withSearch && (
          <div className="search-filter">
            <Input onChange={handleSearchChange} value={search} placeholder={"Search for"} allowClear />
          </div>
        )}
        {/* {!!externalFilters && <div className="table-external-filters">{externalFilters}</div>} */}
      </div>
      <div className="table-right-actions">
        {/* {selectedRows.length > 1 && massDeleteAccess && (
          <MassDeleteButton selectedRows={selectedRows} massDeleteProps={massDeleteProps} tableName={tableName} />
        )}
        {exportAccess && (
          <ExportDropdown
            exportProps={exportProps}
            columns={columns}
            tableService={tableService}
            selectedRows={selectedRows}
          />
        )}
        {newItemAccess && <NewItemButton newItemProps={newItemProps} />} */}
        {externalActions && <div className="table-external-actions">{externalActions}</div>}
      </div>
    </div>
  );
};

export default TableFiltersActions;
