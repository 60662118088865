import React from "react";
import { Typography } from "antd";
import SmartTable from "../../../components/SmartTable";
import TableService from "../../../components/SmartTable/TableService";
import { useLoggedUser } from "../../../hooks/useLoggedUser";
import { TABLE_USERS_MANAGEMENT, generateUsersColumns } from "./methods";

const UsersTable = () => {
  const loggedUser = useLoggedUser();
  const tableKeyName = `${TABLE_USERS_MANAGEMENT}_${loggedUser?.id}`;
  const localStorageTableFilters = JSON.parse(localStorage.getItem(tableKeyName) || "{}");

  const [tableService] = React.useState(
    new TableService({
      defaultOrderBy: [{ column: "email", type: "asc" }],
      ...localStorageTableFilters
    })
  );

  return (
    <div className="users-management flex-column shadow-wrapper">
      <Typography.Title level={5} /*className="section-title table-name"*/ className="container-header">
        Users management
      </Typography.Title>
      <SmartTable
        name={TABLE_USERS_MANAGEMENT}
        tableService={tableService}
        columns={generateUsersColumns()}
        route={"api/users"}
        tableLocalStorageKeyName={tableKeyName}
        rowSelection={false}
        scroll={{ x: 800 }}
      />
    </div>
  );
};

export default UsersTable;
