import React from "react";
import { Typography, message } from "antd";
import { useDispatch } from "react-redux";
import { DownCircleFilled } from "@ant-design/icons";
import Form from "../../../components/Form";
import { Input, PasswordInput } from "../../../components/Input";
import { Select } from "../../../components/Select";
import { fieldRules } from "../../../components/Form/methods";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import "../style.scss";
import { USER_TYPES } from "../../../utils/constants";
import { emailValidation, passwordValidation } from "../../../components/Form/utils";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { TABLE_USERS_MANAGEMENT } from "../Users/methods";
import { IconButton } from "../../../components/Button";

const AddNewUserForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { loading, generalError, submit } = useFormSubmit({
    form,
    route: "/api/users",
    onSuccess: () => {
      message.success("The user has been successfully added");
      dispatch(reloadTable({ name: TABLE_USERS_MANAGEMENT }));
      form.resetFields();
    }
  });

  return (
    <div className="horizontal-form flex-column">
      <Typography.Title level={5} className="section-title">
        Add new user
      </Typography.Title>
      <Form
        onSubmit={submit}
        form={form}
        errors={generalError}
        loading={loading}
        layout="inline"
        className="pt-10 flex-items-center"
      >
        <Form.Item name="email" rules={emailValidation}>
          <Input placeholder="E-Mail" />
        </Form.Item>
        <Form.Item name="password" rules={passwordValidation} style={{ whiteSpace: "pre-line" }}>
          <PasswordInput placeholder="new password" />
        </Form.Item>
        <Form.Item
          name="password_confirmation"
          dependencies={["password"]}
          rules={[
            ...passwordValidation,
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The password does not match");
              }
            })
          ]}
        >
          <PasswordInput placeholder="confirm password" />
        </Form.Item>
        <Form.Item
          name="view"
          rules={fieldRules({
            required: {
              message: "This field is required"
            }
          })}
          className="select-menu"
        >
          <Select
            options={Object.values(USER_TYPES).map((value) => ({ value, label: value }))}
            suffixIcon={<DownCircleFilled className="action-icon" />}
            size="large"
            placeholder="select view"
          />
        </Form.Item>
        <div className="form-actions">
          <IconButton type="primary" htmlType="submit" disabled={loading} loading={loading} className="submit-button">
            Submit
          </IconButton>
        </div>
      </Form>
    </div>
  );
};

export default AddNewUserForm;
