import axiosBase from "axios";
import config from "./config";
import { getStorageAccessToken, deleteStorageAccessToken } from "./components/_auth/methods";

const axios = axiosBase.create({
  baseURL: config.apiBaseUrl,
  withCredentials: true,
  headers: { Accept: "application/json" },
  transformRequest: [
    function addCredentialsToRequest(data, headers) {
      const accessToken = getStorageAccessToken();

      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      return data;
    },
    ...axiosBase.defaults.transformRequest
  ],
  transformResponse: [
    function transformResponse(data) {
      return JSON.parse(data);
    }
  ]
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      if (getStorageAccessToken()) {
        deleteStorageAccessToken();
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
