import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import CheckToken from "../../components/_auth/CheckToken";
import loadable from "../../utils/loadable";
import Layout from "../../components/_layout";
import AdminPage from "../Admin";
import routes from "./routes";
import { AuthenticatedRoutes, UnauthenticatedRoutes } from "./Authorization";

const LoginPage = loadable(() => import("../Login"));
const ForgotPasswordPage = loadable(() => import("../ForgotPassword"));
const ResetPasswordPage = loadable(() => import("../ResetPassword"));
const NotFoundPage = loadable(() => import("../NotFound"));
const Planung = loadable(() => import("../Planung"));
const PlanIst = loadable(() => import("../PlanIst"));
const PlanBurned = loadable(() => import("../PlanIst/PlanBurned"));
const Sprintplanung = loadable(() => import("../Sprintplanung"));
const SharedSP = loadable(() => import("../SharedSP"));

const App = () => {
  return (
    <CheckToken>
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route element={<UnauthenticatedRoutes />}>
              <Route path={routes.LOGIN_PAGE} element={<LoginPage />} />
              <Route path={routes.FORGOT_PASSWORD_PAGE} element={<ForgotPasswordPage />} />
              <Route path={routes.RESET_PASSWORD_PAGE} element={<ResetPasswordPage />} />
            </Route>
            <Route element={<AuthenticatedRoutes />}>
              <Route path={"/"} element={<Navigate to={routes.PLANUNG} />} />
              <Route path={routes.ADMIN_PAGE} element={<AdminPage />} />
              <Route path={routes.PLANUNG} element={<Planung />} />
              <Route path={routes.PLAN_BURNED} element={<PlanBurned />} />
              <Route path={routes.PLAN_IST} element={<PlanIst />} />
              <Route path={routes.SPRINTPLANUNG} element={<Sprintplanung />} />
              <Route path={routes.SHARED_SP} element={<SharedSP />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
            <Route>
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </CheckToken>
  );
};

export default App;
