import React from "react";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { DownCircleFilled } from "@ant-design/icons";
import { reloadTable } from "../../../components/SmartTable/reducer";
import axios from "../../../axios";
import TableCell from "../../../components/SmartTable/TableCell";
import DeleteItemButton from "../../../components/SmartTable/TableFiltersActions/DeleteItemButton";
import { USER_TYPES } from "../../../utils/constants";
import { Select } from "../../../components/Select/index";
import { TABLE_USERS_MANAGEMENT } from "./methods";

const UserActionsCell = (props) => {
  const dispatch = useDispatch();
  const { id, email } = props;

  const handleOnChange = () => {
    return async (value) =>
      axios
        .put(`/api/change-view/${id}`, { "view": value })
        .then(() => {
          message.success("The view has been successfully changed");
          dispatch(reloadTable({ name: TABLE_USERS_MANAGEMENT }));
        })
        .catch((res) => {
          message.error(res.message);
        });
  };

  return (
    <TableCell minWidth={200}>
      <div className="table-actions">
        <Select
          options={Object.values(USER_TYPES).map((value) => ({ value, label: value }))}
          onChange={handleOnChange()}
          suffixIcon={<DownCircleFilled className="action-icon" />}
          size="large"
          placeholder="change view"
        />
        <DeleteItemButton
          tableName={TABLE_USERS_MANAGEMENT}
          route={`/api/users/${id}`}
          content={<span>Are you sure you want to delete the {email} user?</span>}
          className="delete"
        />
      </div>
    </TableCell>
  );
};
export { UserActionsCell };
