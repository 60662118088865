import isEmpty from "lodash/isEmpty";
import config from "../../config";

export const deleteStorageAccessToken = () => {
  localStorage.removeItem(config.localStorageKeys.token);
};

export const saveStorageAccessToken = (token) => {
  localStorage.setItem(config.localStorageKeys.token, token);
};

export const getStorageAccessToken = () => {
  return localStorage.getItem(config.localStorageKeys.token);
};

export const checkIsLogged = (user) => !isEmpty(user);
