import React from "react";

const Delete = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="svg-icon"
      width="1em"
      height="1em"
      style={{ verticalAlign: "middle", overflow: "hidden" }}
      fill={props.color || "#1F0281"}
      viewBox="0 0 1024 1024"
      version="1.1"
      //   viewBox="0 0 14 14"
    >
      <path d="M128 320l0 640c0 35.20512 28.79488 64 64 64l576 0c35.20512 0 64-28.79488 64-64l0-640-704 0zM320 896l-64 0 0-448 64 0 0 448zM448 896l-64 0 0-448 64 0 0 448zM576 896l-64 0 0-448 64 0 0 448zM704 896l-64 0 0-448 64 0 0 448z" />
      <path d="M847.99488 128l-207.99488 0 0-79.99488c0-26.39872-21.6064-48.00512-48.00512-48.00512l-224.01024 0c-26.39872 0-48.00512 21.6064-48.00512 48.00512l0 79.99488-207.99488 0c-26.39872 0-48.00512 21.6064-48.00512 48.00512l0 79.99488 832 0 0-79.99488c0-26.39872-21.6064-48.00512-48.00512-48.00512zM576 128l-192 0 0-63.20128 192 0 0 63.20128z" />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M128 320l0 640c0 35.20512 28.79488 64 64 64l576 0c35.20512 0 64-28.79488 64-64l0-640-704 0zM320 896l-64 0 0-448 64 0 0 448zM448 896l-64 0 0-448 64 0 0 448zM576 896l-64 0 0-448 64 0 0 448zM704 896l-64 0 0-448 64 0 0 448z"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M847.99488 128l-207.99488 0 0-79.99488c0-26.39872-21.6064-48.00512-48.00512-48.00512l-224.01024 0c-26.39872 0-48.00512 21.6064-48.00512 48.00512l0 79.99488-207.99488 0c-26.39872 0-48.00512 21.6064-48.00512 48.00512l0 79.99488 832 0 0-79.99488c0-26.39872-21.6064-48.00512-48.00512-48.00512zM576 128l-192 0 0-63.20128 192 0 0 63.20128z"
      />
    </svg>
  );
};

export default Delete;
