import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

export const useLoggedUser = () => {
  const userState = useSelector((state) => state.auth?.user);

  if (isEmpty(userState)) {
    return {};
  }

  return userState;
};
