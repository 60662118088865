import forOwn from "lodash/forOwn";
import config from "../../config";

/**
 * Create field rules
 */
export const fieldRules = ({ required, email, max, min }, additionalRules = []) => {
  const result = [];

  if (required) {
    result.push({
      required: true,
      message: required.message
    });
  }

  if (email) {
    result.push({
      type: "email",
      message: email.message
    });
  }

  if (max) {
    result.push({
      max: max.value,
      message: max.message
    });
  }

  if (min) {
    result.push({
      min: min.value,
      message: min.message
    });
  }

  return [...result, ...additionalRules];
};

/**
 * @param {object} errorResponse
 * @param {object} formValues
 * @return {object}
 * ex: {
 *   formErrors: [{ name: fieldName, value: fieldValue, errors: [error1, error2, ...] }],
 *   generalErrors: ['Error 1', ...]
 * }
 */
export const prepareErrors = (errorResponse, formValues = {}) => {
  const formErrors = [];
  let generalErrors = [];
  forOwn(errorResponse, (errItem, key) => {
    if (key !== "general") {
      formErrors.push({
        name: key,
        value: formValues[key] || undefined,
        errors: errItem
      });
    }

    if (key === "general") {
      // generalErrors = [...generalErrors, ...errItem];
      generalErrors = [...generalErrors, errItem];
    }
  });

  if (formErrors.length === 0 && generalErrors.length === 0) {
    generalErrors = [config.generalError];
  }

  return {
    formErrors,
    generalErrors
  };
};

/**
 * @description This method is called when an ajax request from a form is failed
 * @param {object} errors
 * @param {boolean} showFieldError
 * @param {object} form
 */
export const catchFormErrors = (errors = {}, form, showFieldError = true) => {
  const { formErrors, generalErrors } = prepareErrors(errors, form.getFieldsValue());
  if (showFieldError) {
    form.setFields(formErrors);
    if (formErrors?.length > 0) {
      form.scrollToField(formErrors[0].name, { scrollMode: "if-needed", behavior: "smooth", block: "center" });
    }
  }
  return { formErrors, generalErrors };
};

export const translateFormErrors = (form) => {
  const fieldsError = form.getFieldsError();
  const fieldsWithErrors = fieldsError.filter((field) => {
    return field?.errors?.length > 0;
  });
  const listOfFieldsWithErrors = fieldsWithErrors.map((item) => item.name[0]);

  if (listOfFieldsWithErrors?.length > 0) {
    form.validateFields(listOfFieldsWithErrors);
  }
};
