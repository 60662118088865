import { Button, Space } from "antd";
import React from "react";
import moment from "../../../moment";
import DateRangePicker from "../../DateRangePicker";

export const getColumnDateFilterProps = ({ tableParams = {}, column = {} }) => {
  const filterColumn = tableParams.filters?.filter((item) => item.columns === column.dataIndex);
  const filterValue =
    filterColumn.length > 0 ? [moment(filterColumn[0].values), moment(filterColumn[1].values)] : undefined;

  const onChange = (setSelectedKeys) => (val) => {
    setSelectedKeys(val);
  };

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="table-filter-dropdown filter-range-date-picker">
        <DateRangePicker
          size="medium"
          value={selectedKeys}
          onChange={onChange(setSelectedKeys)}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} size="small" style={{ width: 80 }}>
            Apply
          </Button>
          <Button
            style={{ width: 80 }}
            size="small"
            type="secondary"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            Preview
          </Button>
        </Space>
      </div>
    ),
    filteredValue: filterValue || null
  };
};
