import React from "react";
import { Input as AntdInput, InputNumber as AntdInputNumber } from "antd";
import classNames from "classnames";

const Input = (props) => {
  return <AntdInput size="large" autoComplete="off" {...props} />;
};

const PasswordInput = (props) => {
  return <AntdInput.Password visibilityToggle={false} size="large" autoComplete="off" {...props} />;
};

const InputNumber = (props) => {
  return <AntdInputNumber size="large" autoComplete="off" {...props} />;
};

const SearchInput = (props) => {
  return (
    <AntdInput.Search
      allowClear
      addonAfter={false}
      size="large"
      autoComplete="off"
      {...props}
      className={classNames("search-field", props.className)}
    />
  );
};

export { Input, PasswordInput, SearchInput, InputNumber };
