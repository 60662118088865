import React from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { openConfirm } from "../../Modal/Confirm";
import Tooltip from "../../Tooltip";
import { reloadTable } from "../reducer";
import axios from "../../../axios";
import { openGeneralErrorNotification } from "../../Notification";
import Delete from "./Delete";

const DeleteItemButton = (props) => {
  const { content, route, tableName, disabled, onDelete, children, onOk, className, stopPropagation } = props;
  const dispatch = useDispatch();

  const reloadTableData = () => {
    if (tableName) {
      dispatch(reloadTable({ name: tableName, deleted: true }));
    }
  };

  const openConfirmModal = (e) => {
    e && e.preventDefault();

    if (stopPropagation) {
      e.stopPropagation();
    }

    if (disabled) {
      return;
    }

    openConfirm({
      content,
      onOk: () => {
        if (onOk) {
          return onOk();
        }

        return axios.delete(route).then(
          (res) => {
            onDelete && onDelete(res);
            message.success("Item has been successfully deleted");
            reloadTableData();
          },
          (error) => {
            openGeneralErrorNotification({ error, defaultError: "Internal server error" });
          }
        );
      },
      zIndex: 99999
    });
  };

  const childrenWithClick = children ? React.cloneElement(props.children, { onClick: openConfirmModal }) : null;

  return (
    <Tooltip title={"Delete"} placement="top" className="text-danger">
      {childrenWithClick || (
        <a disabled={disabled} href="/" onClick={openConfirmModal} className={className}>
          <Delete />
        </a>
      )}
    </Tooltip>
  );
};

export default DeleteItemButton;
