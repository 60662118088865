import TableCell from "../../../components/SmartTable/TableCell";
import { USER_TYPES } from "../../../utils/constants";
import "./styles.scss";
import { FILTER_TYPE_STRING, FILTER_TYPE_SELECT } from "../../../components/SmartTable/methods";
import { UserActionsCell } from "./UserTableActions";

export const TABLE_USERS_MANAGEMENT = "USERS_MANAGEMENT";
export const generateUsersColumns = () => {
  return [
    {
      title: "E-Mail",
      dataIndex: "email",
      width: 200,
      sorter: true,
      filterType: FILTER_TYPE_STRING,
      render: (text, row) => <TableCell minWidth={200}>{row.email || "-"}</TableCell>
    },
    {
      title: "View",
      dataIndex: "view",
      width: 200,
      sorter: true,
      filterType: FILTER_TYPE_SELECT,
      filterProps: {
        options: Object.values(USER_TYPES).map((value) => ({ value, name: value })),
        mode: "multiple"
      },
      render: (text, row) => <TableCell minWidth={200}>{row.view || "-"}</TableCell>
    },
    {
      title: "Settings",
      width: 200,
      render: (text, row = {}) => {
        return <UserActionsCell {...row} />;
      }
    }
  ];
};
