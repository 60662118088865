import React from "react";
import uniqueId from "lodash/uniqueId";
import { Alert } from "antd";

const ErrorList = ({ errors }) => {
  if (errors && errors.length) {
    return (
      <Alert
        className="error-list"
        type="error"
        message={
          <ul>
            {errors.map((errorItem) => (
              <li key={uniqueId("error_item_")}>{errorItem}</li>
            ))}
          </ul>
        }
      />
    );
  }

  return null;
};

export default ErrorList;
