import React from "react";
import { Spin } from "antd";
import Transition from "react-transition-group/Transition";
import { useDispatch } from "react-redux";
import { userAuthenticatedAction, userUnauthenticatedAction } from "./reducer";
import useCheckToken from "./useCheckToken";
import { getStorageAccessToken, deleteStorageAccessToken } from "./methods";

export const GlobalPreloader = ({ state }) => (
  <div className={`global-preloader ${state}`}>
    <Spin className="preloader" size="large" />
  </div>
);

/**
 * @description This component will be shown while token is checked
 */
const CheckToken = ({ children }) => {
  const dispatch = useDispatch();
  const { user, loading, isError } = useCheckToken();

  if (user) {
    dispatch(userAuthenticatedAction(user));
  }

  if (isError && getStorageAccessToken()) {
    deleteStorageAccessToken();
    dispatch(userUnauthenticatedAction());
  }

  return (
    <div className="app-global-container">
      <Transition in={loading} enter={false} timeout={300} mountOnEnter unmountOnExit>
        {(state) => <GlobalPreloader state={state} />}
      </Transition>
      {!loading ? children : null}
    </div>
  );
};

export default CheckToken;
