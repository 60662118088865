import React from "react";
import { DatePicker as AntdDatepicker } from "antd";
import moment from "../../moment";

const DatePicker = (props) => {
  const { disabledDate, children, value, ...rest } = props;
  const pickerProps = {
    size: "large",
    disabledDate,
    value: value ? moment(value) : value,
    ...rest
  };

  if (children) {
    return (
      <div className="form-field-with-children">
        <AntdDatepicker {...pickerProps} />
        {children}
      </div>
    );
  }

  return <AntdDatepicker {...pickerProps} />;
};

DatePicker.RangePicker = AntdDatepicker.RangePicker;

export default DatePicker;
