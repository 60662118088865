import { BUSINESS_UNITS, BUSINESS_UNITS_NO_DEM, USER_TYPES } from "../utils/constants";
import { useLoggedUser } from "./useLoggedUser";

const computeBusinessUnitsList = (view) => {
  if (view === USER_TYPES.ADMIN || view === USER_TYPES.OPEX || view === USER_TYPES.DEM)
    return Object.values(BUSINESS_UNITS);
  return [BUSINESS_UNITS[view]];
};

const computeBusinessUnitsDEMList = (view) => {
  if (view === USER_TYPES.ADMIN || view === USER_TYPES.OPEX || view === USER_TYPES.DEM)
    return Object.values(BUSINESS_UNITS_NO_DEM);
  return [BUSINESS_UNITS_NO_DEM[view]];
};

export const usePermissions = () => {
  const { view } = useLoggedUser();

  const hasBusinessUnit = (currentView) => {
    if (!currentView) return true;

    const businessUnitsList = computeBusinessUnitsList(view).map((item) => item.toLowerCase());
    return businessUnitsList.includes(currentView.toLowerCase());
  };

  const hasDEMBu = (currentView) => {
    if (!currentView) return true;

    const businessUnitsDEMList = computeBusinessUnitsDEMList(view).map((item) => item.toLowerCase());
    return businessUnitsDEMList.includes(currentView.toLowerCase());
  };

  const hasModule = (module) => {
    if (!module) return true;
    return view === USER_TYPES.ADMIN || view === USER_TYPES.OPEX;
  };

  return { hasBusinessUnit, hasDEMBu, hasModule };
};
