import pickBy from "lodash/pickBy";
import forEach from "lodash/forEach";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import moment from "../../moment";
import config from "../../config";
import { FILTER_TYPE_DATE_RANGE } from "./methods";

class TableService {
  constructor(initialParams = {}) {
    this.initialParams = initialParams;
    this.params = initialParams.params;
    this.search = initialParams.search || "";
    this.year = initialParams.year;
    this.filters = initialParams.filters || [];
    this.customFilters = initialParams.customFilters || [];
    this.orderBy = initialParams.orderBy || [];
    this.perPage = initialParams.perPage || 10;
    this.defaultPageSize = initialParams.defaultPageSize || 10;
    this.page = initialParams.page || 1;
    this.total = null;
    // this.onFilterChange = initialParams.onFilterChange;
    this.period = {
      start: null,
      end: null
    };
  }

  getSearchValue() {
    return this.search;
  }

  setSearchValue(search) {
    this.search = search;
  }

  getYear() {
    return this.year;
  }

  addYear(year) {
    this.year = year;

    return this;
  }

  manageColumnFilters(filters = {}, columns = []) {
    forEach(filters, (value, columnKey) => {
      this.removeFilter(columnKey);
      if (isEmpty(value)) return this;

      const columnProps = columns.filter((item) => item.dataIndex === columnKey)[0];

      if (columnProps.filterType === FILTER_TYPE_DATE_RANGE) {
        this.addFilter({
          columns: columnKey,
          operations: ">=",
          values: moment(value[0]).format(config.defaultDateFormat)
        });
        this.addFilter({
          columns: columnKey,
          operations: "<=",
          values: moment(value[1]).format(config.defaultDateFormat)
        });

        return this;
      }

      this.addFilter({
        columns: columnKey,
        operations: isArray(value) ? "in" : "ilike",
        values: value
      });
    });

    return this;
  }

  removeAllFilters() {
    this.filters = [];
    this.customFilters = [];
    this.search = "";
    this.page = 1;

    return this;
  }

  addFilter({ columns, operations, values }) {
    const filter = {
      columns,
      operations,
      values
    };
    this.filters.push(filter);
    // this.onFilterChange && this.onFilterChange([...this.filters]);

    return this;
  }

  addCustomFilter({ key, value }) {
    const filter = { [key]: value };
    this.customFilters.push(filter);
    // this.onFilterChange && this.onFilterChange([...this.filters]);

    return this;
  }

  removeFilter(columnKey) {
    this.filters = this.filters.filter((filter) => filter.columns !== columnKey);
    // this.onFilterChange && this.onFilterChange([...this.filters]);
    return this;
  }

  removeAllColumnsFilters() {
    this.filters = [];

    return this;
  }

  removeCustomFilter(key) {
    this.customFilters = this.customFilters.filter((filter) => Object.keys(filter)[0] !== key);
    // this.onFilterChange && this.onFilterChange([...this.filters]);
    return this;
  }

  manageOrderBy({ column, type }) {
    const existingOrder = this.orderBy.filter((item) => item.column === column).length > 0;
    if (existingOrder) {
      this.orderBy.removeOrderBy(column);
    }

    if (type) {
      let formattedType = type === "ascend" ? "asc" : type === "descend" ? "desc" : type;
      this.orderBy.push({ column, type: formattedType });
    }

    return this;
  }

  removeOrderBy(column) {
    this.orderBy = this.orderBy.filter((item) => item.column !== column);
    return this;
  }

  removeAllOrderBy() {
    this.orderBy = [];
    return this;
  }

  setPerPage(perPage, checkInitial = false) {
    if (checkInitial) {
      this.perPage = this.initialParams.perPage || perPage || 10;
    } else {
      this.perPage = perPage || 10;
    }

    return this;
  }

  setDefaultPerPage(defaultPerPage) {
    this.defaultPageSize = defaultPerPage || 10;

    return this;
  }

  getPerPage() {
    return this.perPage;
  }

  setPage(page) {
    this.page = page || 1;

    return this;
  }

  getPage() {
    return this.page;
  }

  setTotal(total) {
    this.total = total || 0;

    return this;
  }

  getTotal() {
    return this.total;
  }

  getArrayValues(arrayValue = []) {
    if (arrayValue?.length === 0) {
      return undefined;
    }

    return arrayValue.filter((item) => item !== undefined);
  }

  applyDefaultParams(currentValue, defaultValue) {
    return currentValue || defaultValue;
  }

  applyPermanentFilters() {
    if (this.initialParams?.permanentFilters) {
      this.initialParams.permanentFilters.forEach((item) => {
        this.removeFilter(item.column);
        this.addFilter(item);
      });
    }
  }

  getFilters() {
    this.applyPermanentFilters();

    const orderKey = this.applyDefaultParams(
      this.getArrayValues(this.orderBy.map((item) => item.column)),
      this.getArrayValues(this.initialParams.defaultOrderBy?.map((item) => item.column))
    );

    const orderType = this.applyDefaultParams(
      this.getArrayValues(this.orderBy.map((item) => item.type)),
      this.getArrayValues(this.initialParams.defaultOrderBy?.map((item) => item.type))
    )?.[0];

    return pickBy(
      pickBy(
        {
          period_start: this.period.start,
          period_end: this.period.end,
          per_page: this.perPage,
          defaultPageSize: this.defaultPageSize,
          page: this.page,
          total: this.total,
          sort: { [orderKey]: orderType },
          filters: {
            columns: this.getArrayValues(this.filters.map((item) => item.columns)),
            operations: this.getArrayValues(this.filters.map((item) => item.operations)),
            values: this.getArrayValues(this.filters.map((item) => item.values))
          },
          customFilters: this.customFilters.reduce((filter, acc) => ({ ...acc, ...filter }), {}),
          search: this.search,
          year: this.year,
          ...(this.params || {})
        },
        (value) => !(isNull(value) || isUndefined(value) || (!value && value !== 0))
      )
    );
  }

  getAllParameters() {
    return {
      params: this.params,
      search: this.search,
      year: this.year,
      filters: this.filters,
      customFilters: this.customFilters,
      orderBy: this.orderBy,
      defaultOrderBy: this.initialParams.defaultOrderBy
    };
  }
}

export default TableService;
