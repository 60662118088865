import { configureStore } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import rootReducers from "./rootReducers";

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === "development") {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  }
});
