import authReducer from "../components/_auth/reducer";
import modalReducer from "../components/Modal/reducer";
import tableReducer from "../components/SmartTable/reducer";
import chartReducer from "../components/Charts/reducer";

const rootReducer = {
  auth: authReducer,
  modal: modalReducer,
  table: tableReducer,
  chart: chartReducer
};

export default rootReducer;
