import React, { useEffect } from "react";
import { Form as AntForm } from "antd";
import classNames from "classnames";
import ErrorList from "../Errors";
import i18n from "../../i18n";
import { usePrevious } from "../../hooks/usePrevious";
import { translateFormErrors } from "./methods";

const Form = (props) => {
  const { children, onSubmit, errors, loading, className, form, ...rest } = props;
  const language = i18n.language;
  const prevLanguage = usePrevious(language);

  useEffect(() => {
    if (language !== prevLanguage) {
      // translate errors
      translateFormErrors(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <AntForm
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      className={classNames("form-component", className, { loading })}
      scrollToFirstError={{ scrollMode: "if-needed", behavior: "smooth", block: "center" }}
      {...rest}
    >
      <ErrorList errors={errors} />
      {children}
    </AntForm>
  );
};

Form.Item = AntForm.Item;
Form.List = AntForm.List;
Form.useForm = AntForm.useForm;
Form.useWatch = AntForm.useWatch;

export default Form;
