import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import useLogout from "../_auth/useLogout";
import { preventDefaultWithCallback } from "../../utils/methods";
import routes from "../../pages/App/routes";
import { usePermissions } from "../../hooks/usePermissions";
import UserCircle from "./UserCircle";

const Tab = (props) => {
  const { pathname } = useLocation();
  const { to, ...rest } = props;
  const isActive = to === pathname;

  return <NavLink to={to} className="header-item" style={{ color: isActive ? "#E3852C" : "#fff" }} {...rest} />;
};

const TabsNavigation = ({ view }) => {
  const { pathname } = useLocation();
  const { submit } = useLogout();
  const { hasBusinessUnit } = usePermissions();

  const isBU = hasBusinessUnit(view);
  const isAdminActive = pathname === routes.ADMIN_PAGE;

  return (
    <div className="header-items">
      {isBU ? (
        <>
          <Tab to={routes.PLANUNG}>Planung</Tab>
          <Tab to={routes.PLAN_IST}>Plan/Burned</Tab>
          <Tab to={routes.SHARED_SP}>Shared SP</Tab>
        </>
      ) : (
        <>
          <Tab to={routes.PLANUNG}>Planung</Tab>
          <Tab to={routes.PLAN_BURNED}>Plan/Burned</Tab>
          <Tab to={routes.PLAN_IST}>Plan/Tempo</Tab>
          <Tab to={routes.SPRINTPLANUNG}>Sprintplanung</Tab>
          <Tab to={routes.SHARED_SP}>Shared SP</Tab>
        </>
      )}
      <Tab to={routes.ADMIN_PAGE}>
        <UserCircle color={isAdminActive ? "#E3852C" : "#fff"} />
      </Tab>
      <a style={{ color: "white" }} href="/" onClick={preventDefaultWithCallback(submit)}>
        <span>Logout</span>
      </a>
      {/*</div>*/}
    </div>
  );
};

export default TabsNavigation;
