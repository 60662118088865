import React from "react";
import { useLoggedUser } from "../../hooks/useLoggedUser";
import PageMetaTagTitle from "../../components/PageMetaTagTitle";
import AddNewUserForm from "./AddNewUser/Form";
import ChangePasswordForm from "./ChangePassword/Form";
import UsersTable from "./Users/Table";

const AdminPage = () => {
  const { view } = useLoggedUser();
  const isAdmin = view === "ADMIN";

  return (
    <>
      <PageMetaTagTitle title={"React Starter - Admin"} />
      <div className="page-wrapper">
        <ChangePasswordForm
          className={isAdmin ? "horizontal-form flex-column" : "credentials-form shadow-wrapper"}
          layout={isAdmin && "inline"}
        />
        {isAdmin && (
          <>
            <AddNewUserForm />
            <UsersTable />
          </>
        )}
      </div>
    </>
  );
};

export default AdminPage;
