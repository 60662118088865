import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./pages/App";
import { GlobalPreloader } from "./components/_auth/CheckToken";
import "./i18n";
import "./styles/index.scss";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    allowUrls: ["production-domain.co"],
    dsn: "", // the url from sentry
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={<GlobalPreloader />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
);
