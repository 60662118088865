const routes = {
  DASHBOARD_PAGE: "/dashboard",
  LOGIN_PAGE: "/login",
  FORGOT_PASSWORD_PAGE: "/forgot-password",
  RESET_PASSWORD_PAGE: "/password-reset/:token",
  ADMIN_PAGE: "/admin",
  PLANUNG: "/planung",
  PLAN_IST: "/plan-ist",
  PLAN_BURNED: "/plan-burned",
  SPRINTPLANUNG: "/sprintplanung",
  SHARED_SP: "/shared-sp"
};

export default routes;
