import { Modal } from "antd";

export const openConfirm = (params = {}) => {
  const {
    title = "Confirm",
    content = "Are you sure you wish to proceed?",
    okText = "Proceed",
    cancelText = "Cancel",
    closable = true,
    closeIcon = <i className="ti-close" />,
    ...rest
  } = params;
  return Modal.confirm({
    title,
    content,
    okText,
    cancelText,
    closable,
    closeIcon,
    ...rest
  });
};
