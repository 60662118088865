import { useEffect, useState } from "react";
import axios from "../../axios";
import { getStorageAccessToken } from "./methods";

const useCheckToken = () => {
  const accessToken = getStorageAccessToken();
  const [requestState, setRequestState] = useState({
    data: null,
    error: null,
    loading: !!accessToken
  });

  useEffect(() => {
    if (accessToken) {
      axios.get("/api/me").then(
        (res) => {
          setRequestState((prevState) => ({ ...prevState, data: res.data.data, loading: false }));
        },
        () => {
          setRequestState((prevState) => ({ ...prevState, data: null, loading: false, error: true }));
        }
      );
    }
  }, [accessToken]);

  const { data, loading, error } = requestState;

  return {
    loading,
    user: data,
    isError: error
  };
};

export default useCheckToken;
