import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLoggedUser } from "../../hooks/useLoggedUser";
import routes from "./routes";

const UnauthenticatedRoutes = () => {
  const { id } = useLoggedUser();

  if (id) {
    return <Navigate replace to={routes.PLANUNG} />;
  }

  return <Outlet />;
};

const AuthenticatedRoutes = () => {
  const { id } = useLoggedUser();

  if (!id) {
    return <Navigate replace to={routes.LOGIN_PAGE} />;
  }

  return <Outlet />;
};

export { UnauthenticatedRoutes, AuthenticatedRoutes };
